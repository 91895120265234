import React, { useEffect } from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import Home from "./Home";
import About from "./website-partials/About";
import Service from "./Service";
import Contact from "./Contact";
import { Routes, Route, Outlet } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";

import "./App.css";
import Dashboard from "./components/dashboard/dashboard";
import WalletTransactions from "./components/dashboard/walletTransactions";
import Deposits from "./components/dashboard/deposits";
import DashboardRight from "./components/dashboard/dashboardRight";
import PayBills from "./components/dashboard/payBills";
import Transactions from "./components/dashboard/transactions";
import Profile from "./components/dashboard/profile";
import WalletTransfer from "./components/dashboard/walletTransfer";
import Kyc from "./components/dashboard/kyc";
import PayWithoutSignin from "./payWithoutSignin";
import ReferralUsers from "./components/dashboard/referralUsers";
import Statements from "./components/dashboard/statements";
import DashboardNav from "./components/dashboard/dashboardNav";
import TopNav from "./components/dashboard/TopNav";
import Bbps from "./components/dashboard/Bbps";
import TermsPage from "./components/dashboard/TermsPage";
import PrivacyPolicyPage from "./components/dashboard/PrivacyPolicyPage";
import ServicesPage from "./website-partials/ServicesPage";
import Bbpsbillrequest from "./components/dashboard/Bbpsbillrequest";
import Bbpspay from "./components/dashboard/Bbpspay";
import Bbpsuccess from "./components/dashboard/Bbpsuccess";
import BbpsTransaction from "./components/dashboard/BbpsTransactions";
import Complaints from "./components/dashboard/BbpsComplaints";
import ComplaintStatus from "./components/dashboard/BbpsStatus";
import BbpsComplaintSearch from "./components/dashboard/BbpsSearch";
import QuickBillpay from "./components/dashboard/Quickpay";
import QuickpayDetails from "./components/dashboard/Quickpaydetails";
import Quickpaysuccess from "./components/dashboard/quickpaysuccess";
import Insurance from "./components/dashboard/Insurance";
import Complaintregistersuccess from "./components/dashboard/Bbpscomplaintregistersuccess";
import StatusFetch from "./components/dashboard/Complaintstatusfetch";
/* import NavBar from './NavBar';
 */
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Searchresult from "./components/dashboard/Bbps-searchresult";
import RefundPolicy from "./components/dashboard/RefundPolicy";
import Success from "./components/dashboard/DummySuccess";
import PaymentFailed from "./components/dashboard/DummyFailure";
import SavedCards from "./components/dashboard/SavedCard";
import AddCards from "./components/dashboard/Addsavedcard";
function App() {
    useEffect(() => {
        Aos.init({});
    }, []);

    function PrivateRoute() {
        return (
            <>
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                {/* <div className="container1">
        <button style={{}}>button</button> */}
                <div>
                    <TopNav />
                </div>

                <div className="col-2 left-side">
                    <DashboardNav />
                </div>

                <Outlet />
                {/* </div> */}
            </>
        );
    }

    return (
        <>
            {/* <NavBar/> */}
            <ToastContainer />
            <Routes>
                <Route path="/aboutus" element={<About />} />
                <Route path="/Success-order" element={<Success />} />
                <Route path="/Payment-failed" element={<PaymentFailed />} />
                <Route path="/services" element={<ServicesPage />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/termsandconditions" element={<TermsPage />} />
                <Route path="/refundPolicy" element={<RefundPolicy />} />
                <Route path="/privacypolicy" element={<PrivacyPolicyPage />} />
                <Route element={<PrivateRoute />}>
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route
                        path="/dashboard/saved-cards"
                        element={<SavedCards />}
                    />
                    <Route
                        path="/dashboard/saved-cards/add"
                        element={<AddCards />}
                    />
                    {/* <Route path="/dashboard/" element={<DashboardRight />} /> */}
                    <Route path="/dashboard/deposits" element={<Deposits />} />
                    <Route path="/dashboard/pay-bills" element={<PayBills />} />
                    <Route
                        path="/dashboard/wallet-transfer"
                        element={<WalletTransfer />}
                    />
                    <Route
                        path="/dashboard/transactions"
                        element={<Transactions />}
                    />
                    <Route
                        path="/dashboard/statements"
                        element={<Statements />}
                    />
                    <Route
                        path="/dashboard/referral-users"
                        element={<ReferralUsers />}
                    />
                    <Route path="/dashboard/profile" element={<Profile />} />
                    <Route path="/dashboard/kyc" element={<Kyc />} />
                    <Route path="/dashboard/bbps" element={<Bbps />} />
                    <Route
                        path="/dashboard/bbps/Biller/:type"
                        element={<Bbpsbillrequest />}
                    />
                    <Route
                        path="/dashboard/bbps/Biller/pay/:type"
                        element={<Bbpspay />}
                    />
                    <Route
                        path="/dashboard/bbps/success/:type"
                        element={<Bbpsuccess />}
                    />
                    <Route
                        path="/dashboard/bbps/transaction"
                        element={<BbpsTransaction />}
                    />
                    <Route
                        path="/dashboard/bbps/complaints"
                        element={<Complaints />}
                    />
                    <Route
                        path="/dashboard/bbps/status"
                        element={<ComplaintStatus />}
                    />
                    <Route
                        path="dashboard/bbps/search-complaint"
                        element={<BbpsComplaintSearch />}
                    />
                    <Route
                        path="/dashboard/bbps/quick-pay"
                        element={<QuickBillpay />}
                    />
                    <Route
                        path="/dashboard/bbps/quick-pay/details"
                        element={<QuickpayDetails />}
                    />
                    <Route
                        path="/dashboard/bbps/quick-pay/QuickPaySuccess"
                        element={<Quickpaysuccess />}
                    />
                    <Route
                        path="/dashboard/bbps/insurance"
                        element={<Insurance />}
                    />
                    <Route
                        path="/dashboard/bbps/complaints/registersuccess"
                        element={<Complaintregistersuccess />}
                    />
                    <Route
                        path="/dashboard/bill-payments/billPaymentComplaintStatusFetch"
                        element={<StatusFetch />}
                    />
                    <Route
                        path="/dashboard/bbps/searchresult"
                        element={<Searchresult />}
                    />
                </Route>
                <Route path="/payment/:recId" element={<PayWithoutSignin />} />

                <Route path="/" element={<Home />} />
            </Routes>
        </>
    );
}

export default App;
