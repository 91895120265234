import React, { useState, useEffect, useRef } from "react";

import { useNavigate } from "react-router-dom";
import DashboardNav from "./dashboardNav";
import "./dashboard.css";
import WalletBlcHeader from "./walletBlcHeader";
import LoginServices from "../../services/LoginServices";
import Razor from "./Razor";
import PineLabs from "./PineLabs";
import { Container } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

function Deposits() {
    const navigate = useNavigate();
    const componentMounted = useRef(true);
    const [walletBlc, setWalletBlc] = useState<any>(0);
    const [addBlc, setAddBlc] = useState<any>();
    const [notes, setNotes] = useState("");
    const [number, setNumber] = useState<any>();
    const [email, setEmail] = useState("");
    const userId = localStorage.getItem("userid");
    const [commission, setCommission] = useState({
        WalletCommissionPercentage: 0,
        WalletCommissionPercentage1: 0,
    });
    const [gateway, setGateway] = useState<any>("pg1");
    const [key, setkey] = useState<any>(process.env.REACT_APP_RAZORPAY_KEY);
    const [gatewaytwoStatus, setgatewaytwoStatus] = useState<any>();
    const [gatewayoneStatus, setgatewayoneStatus] = useState<any>();
    const [pg2commission, setpg2commission] = useState<any>(0);

    const fetchData = () => {
        let paymentid = sessionStorage.getItem("paymentid");

        if (userId) {
            let config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            LoginServices.walletCommission(userId, config)
                .then((Response: any) => {
                    console.log(Response, "221");
                    if (
                        Response?.data?.Status == 1 &&
                        componentMounted.current
                    ) {
                        /* setCommission(
                            Response?.data?.WalletCommissionPercentage
                        ); */
                        setCommission(Response?.data);
                    } else {
                    }
                })
                .catch((e: Error) => {
                    console.log(e);
                });
            LoginServices.paymentstatus(paymentid, config)
                .then((Response: any) => {
                    console.log(Response, "2121");
                    // setgatewaytwoStatus(Response?.data);
                    if (
                        Response?.data?.Status == 1 &&
                        componentMounted.current
                    ) {
                        // setgatewaytwoStatus(Response?.data.isPG2enabled);
                        setgatewayoneStatus(Response?.data.isPG1enabled);
                        if (Response?.data.isPG1enabled === 1) {
                            setGateway("pg1");
                        } else {
                            setGateway("pg2");
                        }
                        if (Response?.data?.userType == "USER") {
                            setpg2commission(
                                Response?.data.pg2walletCommissionUserPercentage
                            );
                        } else {
                            setpg2commission(
                                Response?.data
                                    .pg2walletCommissionBusinessPercentage
                            );
                        }
                    } else {
                    }
                })
                .catch((e: Error) => {
                    console.log(e);
                });
            LoginServices.getWalletBalanceByUserID(parseInt(userId), config)
                .then((response: any) => {
                    console.log(response);
                    if (response.data.Status == 1 && componentMounted.current) {
                        setWalletBlc(response?.data?.walletBalance);
                    } else {
                    }

                    console.log(response.data);
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        } else {
            alert("Login to see your dashboard");
            navigate("/");
        }
    };
    const handleVisibilityChange = () => {
        if (
            document.visibilityState === "visible" &&
            componentMounted.current
        ) {
            console.log("Visibility");
            fetchData();
        }
    };

    useEffect(() => {
        fetchData(); // Initial API call on page load

        // Add event listener for visibility change
        document.addEventListener("visibilitychange", handleVisibilityChange);

        // Clean up the event listener when the component is unmounted
        return () => {
            document.removeEventListener(
                "visibilitychange",
                handleVisibilityChange
            );
            componentMounted.current = false;
        };
    }, []);

    const gatewaychange = (e: any) => {
        setkey(e.target.value);
        if (e.currentTarget.getAttribute("id") == "PineLabs") {
            setGateway("pg2");
        } else {
            setGateway("pg1");
        }
    };
    return (
        <>
            <div className="row main-row">
                <div className="col-10 right-back">
                    <WalletBlcHeader wBlc={walletBlc}></WalletBlcHeader>
                    <p className="deposit-head">Deposit</p>
                    <div className="total-wallet-box-main">
                        <div className="total-wallet-box-sub">
                            <div>
                                <p className="total-blc-p">
                                    Total Wallet Balance
                                </p>
                                <p className="total-blc-rs">₹ {walletBlc}</p>
                            </div>
                        </div>
                        <div>
                            <div
                                className="ip-inr-main"
                                style={{ marginBottom: "10px" }}>
                                <label className="ip-inr58">₹</label>
                                <input
                                    type="text"
                                    name="amount"
                                    value={addBlc}
                                    id="amount"
                                    onChange={(e: any) => {
                                        const inputNumber = parseFloat(
                                            e.target.value.replace(
                                                /[^0-9.]/g,
                                                ""
                                            )
                                        );
                                        setAddBlc(
                                            isNaN(inputNumber)
                                                ? ""
                                                : inputNumber.toLocaleString(
                                                      "en-IN",
                                                      {
                                                          maximumFractionDigits: 2,
                                                      }
                                                  )
                                        );
                                    }}
                                    autoComplete="off"
                                    className="add-blc-ip"
                                    placeholder="Enter Amount"
                                />
                            </div>

                            {/*   <div
                                className="ip-inr-main"
                                style={{ marginBottom: "10px" }}>
                                <input
                                    type="number"
                                    name="number"
                                    value={number}
                                    id="number"
                                    onChange={(e: any) => {
                                        setNumber(e.target.value);
                                    }}
                                    autoComplete="off"
                                    className="add-blc-ip"
                                    placeholder="Enter Number"
                                />
                            </div>
 */}
                            {/*  <div
                                className="ip-inr-main"
                                style={{ marginBottom: "10px" }}>
                                <input
                                    type="email"
                                    name="email"
                                    value={email}
                                    id="email"
                                    onChange={(e: any) => {
                                        setEmail(e.target.value);
                                    }}
                                    autoComplete="off"
                                    className="add-blc-ip"
                                    placeholder="Enter Email id"
                                />
                            </div>
 */}
                            <div
                                className="ip-inr-main"
                                style={{ marginTop: "-10px" }}>
                                <textarea
                                    className="add-blc-notes"
                                    name="amount"
                                    value={notes}
                                    id="amount"
                                    onChange={(e: any) => {
                                        setNotes(e.target.value);
                                    }}
                                    placeholder="Deposit for"
                                />
                            </div>
                            {/* <div className="commission">
                                <p className="deduction">
                                    {" "}
                                    As per below % will be deducted as a
                                    processing Fee.
                                </p>
                            </div> */}
                            {/*     <div className="payment-btns">
                                {gatewayoneStatus == 1 && (
                                    <div>
                                        <input
                                            type="radio"
                                            id="Razor Pay"
                                            name="Payment"
                                            value={"one"}
                                            checked={gateway == "pg1"}
                                            onChange={gatewaychange}
                                        />
                                        <label htmlFor="Razor Pay">
                                            {" "}
                                            PG-1({commission}%)
                                            <br />
                                            NORMAL CARDS
                                        </label>
                                    </div>
                                )}

                                {gatewaytwoStatus == 1 && (
                                    <div>
                                        <input
                                            type="radio"
                                            id="PineLabs"
                                            name="Payment"
                                            checked={gateway == "pg2"}
                                            value={"two"}
                                            onChange={gatewaychange}
                                        />
                                        <label htmlFor="PineLabs">
                                            {" "}
                                            PG-2({pg2commission}%)
                                            <br />
                                            PREPAID CARDS
                                        </label>
                                    </div>
                                )}
                            </div> */}
                            <div className="Pg_charges">
                                {/* <h3>Note :</h3> */}
                                <ul
                                    className="all_charges"
                                    style={{
                                        paddingLeft: "1rem",
                                    }}>
                                    <li>PAYU (VISA/RUPAY) -1.4%</li>
                                    <li>PAYUED (VISA/RUPAY) -3.2%</li>
                                    <li>EASEBUZZ (VISA/RUPAY) -1.8%</li>
                                    <li>
                                        RAZORPAY (VISA/RUPAY) -
                                        {commission?.WalletCommissionPercentage}
                                        %
                                    </li>
                                    <li>
                                        Razorpay edu (HDFC/MASTER/VISA) per
                                        transaction 50k limit -
                                        {
                                            commission?.WalletCommissionPercentage1
                                        }
                                        %
                                    </li>
                                    <li>RAZORPAY OLBUIZ (HDFC) - 2%</li>
                                    <li>
                                        RAZORPAY WALLET (MASTER CARDS) -2.6%
                                    </li>
                                </ul>
                            </div>
                            <div className="add-btn-div">
                                {/* <button onClick={loginFormSubmit} className="add-btn">Add</button> */}
                                {gateway == "pg1" ? (
                                    <Razor
                                        passBlc={addBlc}
                                        number={number}
                                        notes={notes}
                                        setEmail={setEmail}
                                        setNumber={setNumber}
                                        setNotes={setNotes}
                                        setAddBlc={setAddBlc}
                                        email={email}
                                        Gateway={gateway}></Razor>
                                ) : (
                                    <PineLabs
                                        passBlc={addBlc}
                                        fetchData={fetchData}
                                        setNotes={setNotes}
                                        setEmail={setEmail}
                                        setNumber={setNumber}
                                        setAddBlc={setAddBlc}
                                        number={number}
                                        notes={notes}
                                        email={email}
                                        Gateway={gateway}></PineLabs>
                                )}
                            </div>
                            <ToastContainer />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Deposits;
