import React, { useEffect, useState } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement } from "chart.js";
Chart.register(ArcElement);

const styles = {
    pieContainer: {
        width: "200px",
        height: "200px",
        margin: "0 auto",
        //   top: "50%",
        //   left: "50%",
        //   position: "absolute",
        //   transform: "translate(-50%, -50%)"
    },
};

const ChartsCustom = ({ charts }) => {
    const [selectedMonth, setselectedMonth] = useState("");
    const [seletedDeposit, setseletedDeposit] = useState(0);
    const [seletedWithdrawal, setseletedWithdrawal] = useState(0);
    const [totalAmount, setTotalMonth] = useState(null);

    const [monthlyDeposite, setmonthlyDeposite] = useState(0);
    const [monthlyWithdrawal, setmonthlyWithdrawal] = useState(0);

    useEffect(() => {
        setselectedMonth(charts[0].Month);
    }, []);

    useEffect(() => {
        if (charts.length > 0) {
            const seletedMonth = charts.find((item) => {
                if (item.Month == selectedMonth) {
                    return item;
                }
            });

            if (seletedMonth != undefined && seletedMonth != "") {
                setTotalMonth(seletedMonth.AmountTotal);
                const payout =
                    (seletedMonth.WalletLoad / seletedMonth.AmountTotal) * 100;
                const withdraw =
                    (seletedMonth.Payouts / seletedMonth.AmountTotal) * 100;
                setmonthlyDeposite(seletedMonth.WalletLoad);
                setmonthlyWithdrawal(seletedMonth.Payouts);
                setseletedDeposit(payout);
                setseletedWithdrawal(withdraw);
            } else {
                setTotalMonth(0);
                setseletedDeposit(0);
                setseletedWithdrawal(0);
            }
        }
    }, [selectedMonth]);

    const data = {
        labels: ["Deposit", "Withdraw"],
        datasets: [
            {
                data: [seletedDeposit, seletedWithdrawal],
                backgroundColor: ["#6865F0", "#e1ad01"],
                hoverOffset: 4,
            },
        ],
    };

    const options = {
        cutout: "60%",
        elements: {
            point: {
                radius: 0,
                hitRadius: 5,
            },
        },
        tooltips: {
            mode: "index",
            intersect: false,
        },
        hover: {
            mode: "index",
            intersect: false,
        },
    };

    return (
        <div>
            <Row>
                <Col lg={9}>Money Flow</Col>
                <Col>
                    <select
                        name="months"
                        id="months"
                        onChange={(e) => setselectedMonth(e.target.value)}
                        style={{ border: "0px" }}>
                        {charts.length > 0 &&
                            charts.map((item) => (
                                <option value={item.Month}>
                                    {item.Month.substring(0, 3)}
                                </option>
                            ))}
                    </select>
                </Col>
            </Row>
            <div style={styles.pieContainer}>
                <div
                    className="centerr"
                    style={{
                        position: "absolute",
                        marginTop: "90px",
                        marginLeft: "90px",
                    }}>
                    {totalAmount}
                </div>
                <Doughnut data={data} options={options} />
            </div>

            <ul>
                <div>
                    <div
                        className="circle-indicator"
                        style={{
                            backgroundColor: "#6865F0",
                            display: "inline-block",
                        }}></div>
                    <span> Deposit - {monthlyDeposite}</span>
                </div>
                <div>
                    <div
                        className="circle-indicator"
                        style={{
                            backgroundColor: "#e1ad01",
                            display: "inline-block",
                        }}></div>
                    <span> Withdrawals - {monthlyWithdrawal}</span>
                </div>
            </ul>
        </div>
    );
};

export default ChartsCustom;
