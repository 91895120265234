import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import DashboardNav from "./dashboardNav";
import "./dashboard.css";
import WalletBlcHeader from "./walletBlcHeader";
import LoginServices from "../../services/LoginServices";
import Razor from "./Razor";
import { Modal, Row, Col } from "react-bootstrap";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import { BounceLoader } from "react-spinners";
import OtpInput from "react-otp-input";
// import number from 'number-to-words';

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Fuse from "fuse.js";
import LastTenTransactions from "./LastTenTransactions";
import UpiContacts from "./upiContacts";

// console.log(number.toWords(1020))
var convertRupeesIntoWords = require("num-words");

const override = css`
    display: block;
    margin: 0 auto;
    border-color: "#6865F0";
`;

function PayBills() {
    let [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [refreshP, setRefreshP] = useState<any>("");
    const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm();
    const [pinValue, setpinValue] = useState("");
    const [showIfscForm, setshowIfscForm] = useState<any>(true);
    const [showNameNumberForm, setshowNameNumberForm] = useState<any>(false);
    const [showAllFormDetails, setshowAllFormDetails] = useState<any>(false);
    const [userDetails, setUserDetails] = useState<any>({});
    const initialState = { accountNumber: "", IFSCCode: "", beneName: "" };
    const [state, setState] = useState(initialState);
    console.log("re-render");
    const onVerifySubmit = (data: any) => {
        if (userId) {
            const payload = {
                accountNumber: data.accountNumber,
                IFSCCode: data.IFSCCode,
            };

            let config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            setLoading(true);

            LoginServices.verifyAccountNumberAndIfsc(
                parseInt(userId),
                payload,
                config
            )
                .then((response: any) => {
                    setLoading(false);
                    if (response.data.Status == 1) {
                        setState({
                            accountNumber: data.accountNumber,
                            IFSCCode: data.IFSCCode,
                            beneName:
                                response.data.AccountDetails.BENEFICIARY_NAME,
                        });

                        // setState(prevState=>({
                        //     ...prevState,
                        //     beneName:response.data.name
                        // }))
                        alert("VERIFIED");
                        setshowIfscForm(false);
                        setshowNameNumberForm(true);
                        // setRefreshP('yes')
                        // reset()
                    } else {
                        alert(response?.data?.Message);
                    }
                })
                .catch((e: any) => {
                    setLoading(false);
                    console.log(e);
                    alert(e?.response.data?.Message);
                });
        }
    };
    const getUserDetails = () => {
        if (userId) {
            let config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            //isPayoutBlocked
            LoginServices.getUserDetails(userId, config)
                .then((res: any) => {
                    console.log(res);
                    if (res.data.Status == 1) {
                        setUserDetails(res.data.UserDetails);
                    }
                    console.log(res);
                })
                .catch((e: any) => {
                    alert("something went wrong");
                });
        }
    };
    const onNameNumberSubmit = (data: any) => {
        const payload = {
            userId: userId,
            isVerificationRequired: 1,
            name: state.beneName,
            mobileNumber: data.mobileNumber,
            accountNumber: state.accountNumber,
            IFSCCode: state.IFSCCode,
            referenceNo: "dser4545455656",
        };

        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        setLoading(true);
        LoginServices.createWalletContact(payload, config)
            .then((response: any) => {
                setLoading(false);
                if (response.data.Status == 1) {
                    alert(response.data.Message);
                    setshowIfscForm(true);
                    setshowNameNumberForm(false);
                    setShowSection("biller-list");
                    setRefreshP("yes");
                    reset();
                    setState({ accountNumber: "", IFSCCode: "", beneName: "" });
                } else {
                    alert(response?.data?.Message);
                }
            })
            .catch((e: any) => {
                setLoading(false);
                console.log(e);
                alert(e?.response.data?.Message);
            });
    };

    const onSubmit = (data: any) => {
        const payload = {
            userId: userId,
            isVerificationRequired: 1,
            name: data.name,
            mobileNumber: data.mobileNumber,
            accountNumber: data.accountNumber,
            IFSCCode: data.IFSCCode,
        };

        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        setLoading(true);
        LoginServices.createWalletContact(payload, config)
            .then((response: any) => {
                setLoading(false);
                if (response.data.Status == 1) {
                    alert(response.data.Message);
                    setShowSection("biller-list");
                    setRefreshP("yes");
                    reset();
                } else {
                    alert(response?.data?.Message);
                }
            })
            .catch((e: any) => {
                setLoading(false);
                console.log(e);
                alert(e?.response.data?.Message);
            });
    };

    const [walletBlc, setWalletBlc] = useState<any>(0);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [currentPayee, setCurrentPayee] = useState<any>();

    const [payAmount, setPayAmount] = useState<any>("");
    const [payNotes, setPayNotes] = useState<any>();
    const [payoutPin, setPayoutPin] = useState<any>("");
    const [payoutPurpose, setPayoutPurpose] = useState<any>("");
    const [PayoutContactType, setPayoutContactType] = useState<any>("bank");
    const [addBlc, setAddBlc] = useState<number>();
    const userId = localStorage.getItem("userid");
    const [payoutList, setPayoutList] = useState<any>();
    const [resPayoutList, setResPayoutList] = useState<any>();

    const [showSection, setShowSection] = useState<any>("");
    const [showLastTransaction, setshowLastTransaction] = useState(false);
    const [userIpAddress, setUserIpAddress] = useState("");
    useEffect(() => {
        getUserDetails();
        reset();
        setState({ accountNumber: "", IFSCCode: "", beneName: "" });
        fetch("https://api.ipify.org/?format=json")
            .then((Response) => {
                return Response.json();
            })
            .then((response) => {
                setUserIpAddress(response.ip);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    useEffect(() => {
        if (userId) {
            let config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            LoginServices.getWalletBalanceByUserID(parseInt(userId), config)
                .then((response: any) => {
                    if (response.data.Status == 1) {
                        setWalletBlc(response?.data?.walletBalance);
                    } else {
                        alert(response?.data?.Message);
                    }
                })
                .catch((e: Error) => {
                    console.log(e);
                    setShowSection("add-img");
                });
            LoginServices.getPayoutContacts(parseInt(userId), config, "BANK")
                .then((response: any) => {
                    if (response.data.Status == 1) {
                        setPayoutList(response?.data?.Contacts);
                        setResPayoutList(response?.data?.Contacts);

                        if (response?.data?.Contacts?.length == 0) {
                            setShowSection("add-img");
                        } else {
                            setShowSection("biller-list");
                        }
                    } else {
                        alert(response?.data?.Message);
                    }
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        } else {
            alert("Login to see your dashboard");
            navigate("/");
        }
    }, [refreshP == "yes"]);

    const localSearch = (searchItem: string) => {
        if (searchItem?.length == 0) {
            setRefreshP("yes");
            return;
        }
        const fuse = new Fuse(payoutList, {
            includeScore: true,
            keys: [
                "mobileNumber",
                "bankAccountName",
                "type",
                // 'mobileNumber',
                // 'bankIFSCCode',
                // 'emailId',
                // 'name'
            ],
            threshold: 0.2,
        });
        const results = fuse.search(searchItem);
        // const characterResults = results.map(character => character.item);
        // console.log(characterResults);
        if (results.length == 0) {
            setResPayoutList([]);
        } else {
            setResPayoutList(results.map((character) => character.item));
        }

        // console.log(resPayoutList);
    };

    // showSection == 'biller-list'
    function isNumber(n: any) {
        return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
    }
    const payouthandler = (e: any) => {
        setPayoutPurpose(e.target.value);
    };
    const startPaymentStatusCheck = async (orderId: any) => {
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        console.log("sadasdasd");
        LoginServices.PayoutStatusCheck(orderId, config)
            .then((response: any) => {
                if (response.data.Status == 1) {
                    console.log(response);

                    if (response?.data?.OrderStatus == "SUCCESS") {
                        setLoading(false);
                        setRefreshP("yes");
                        setShowModal(false);
                        toast.success(response?.data?.Message, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        setpinValue("");
                    } else {
                        setTimeout(() => {
                            startPaymentStatusCheck(orderId);
                        }, 5000);
                    }
                } else if (response.data.Status == 0) {
                    setLoading(false);
                    toast.error(`${response.data.Message}`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((e: Error) => {
                setLoading(false);
                console.log(e);
            });
    };
    const sendPayment = (item: any) => {
        let confirmPayment = false;

        if (payAmount.length == 0) {
            alert("Amount is mandatory");
            return;
        }
        // if (!isNumber(payAmount)) {
        //     alert("Amount invalid");
        //     return;
        // }
        if (parseFloat(payAmount.replace(/,/g, "")) > walletBlc) {
            alert("Pay amount is more than your wallet balance");
            return;
        }
        if (parseFloat(payAmount.replace(/,/g, "")) > 200000) {
            alert("Pay amount is more than 2,00,000Rs");
            return;
        }
        if (pinValue.length == 0) {
            alert("PIN is mandatory");
            return;
        }
        if (!isNumber(pinValue)) {
            alert("PIN invalid");
            return;
        }
        if (payoutPurpose.length == 0) {
            alert("Bill payment Purpose is mandatory");
            return;
        }
        if (parseFloat(payAmount.replace(/,/g, ""))) {
            if (window.confirm("Are You Sure You Want To Proceed") == true) {
                confirmPayment = true;
            }
        }

        if (confirmPayment) {
            const payload = {
                payout_contactId: item?.payout_contactId,
                amount: parseFloat(payAmount.replace(/,/g, "")),
                notes: payNotes,
                PIN: pinValue,
                ipAddress: userIpAddress,
                payoutPurpose: payoutPurpose,
            };
            let config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };

            setLoading(true);
            LoginServices.payToContact(payload, config)
                .then((response: any) => {
                    if (response.data.Status == 1) {
                        // startPaymentStatusCheck(response?.data?.TransactionId);
                        setLoading(false);
                        setRefreshP("yes");

                        setShowModal(false);
                        navigate("/dashboard");
                        toast.success(response?.data?.Message, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        setpinValue("");
                    }
                    // else {
                    //     alert(response?.data?.Message);
                    //     setLoading(false);
                    // }
                    else if (response.data.Status == 0) {
                        setLoading(false);
                        setLoading(false);
                        toast.error(`${response.data.Message}`, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                })
                .catch((e: any) => {
                    toast.error(`${e.response.data.Message}`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    // console.log();
                    setLoading(false);
                });
        }
    };
    const deletePayee = (payoutid: any) => {
        if (window.confirm("Are you sure want to delete?") && userId) {
            let config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            LoginServices.deletePayoutContact(
                parseInt(userId),
                payoutid,
                config
            )
                .then((response: any) => {
                    if (response.data.Status == 1) {
                        toast.success(response?.data?.Message, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        setRefreshP("yes");
                    } else {
                        alert(response?.data?.Message);
                    }
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        }
    };

    // let converter = require('number-to-words')
    //     converter.toWords('#number-to-words');

    const sortArray = (e: any) => {
        localSearch(e.target.value);
    };

    const onPinChange = (e: any) => {
        setpinValue(e);
        // setPayoutPin(e)
    };

    const hideModel = () => {
        setShowModal(false);
        setpinValue("");
        setPayAmount("");
    };

    const onBackClick = () => {
        setshowIfscForm(true);
        setshowNameNumberForm(false);
        setShowSection("biller-list");
        reset();
        setState({ accountNumber: "", IFSCCode: "", beneName: "" });
    };

    const onCheckBoxClick = () => {
        // reset()
        setshowIfscForm(!showIfscForm);
        setshowAllFormDetails(!showAllFormDetails);
    };

    const getLastTransactionList = () => {
        hideModel();

        setshowLastTransaction(true);
    };

    const onTransactionModelClose = () => {
        setshowLastTransaction(false);
    };
    // console.log(userDetails?.isUPIPayoutsEnabled);

    return (
        <>
            {showLastTransaction && (
                <LastTenTransactions
                    show={showLastTransaction}
                    onHide={onTransactionModelClose}
                    userId={userId}
                    payout_contactId={currentPayee.payout_contactId}
                />
            )}

            <div className="row main-row">
                <div className="custom-col-10 right-back">
                    <WalletBlcHeader wBlc={walletBlc}></WalletBlcHeader>
                    <div className="contactType-btns">
                        <button
                            className={
                                PayoutContactType == "bank"
                                    ? "contactbtnActive"
                                    : "contactbtninActive"
                            }
                            onClick={() => setPayoutContactType("bank")}>
                            Billers
                        </button>
                        {/*   {userDetails?.isUPIPayoutsEnabled == 1 && (
                            <button
                                className={
                                    PayoutContactType == "upi"
                                        ? "contactbtnActive"
                                        : "contactbtninActive"
                                }
                                onClick={() => {
                                    setPayoutContactType("upi");
                                }}>
                                Upi Contacts
                            </button>
                        )} */}
                    </div>
                    {PayoutContactType === "bank" ? (
                        <>
                            {showSection == "add-img" && (
                                <div>
                                    <div className="pay-bills-grp1">
                                        <p className="pay-bills-head">
                                            Pay Bills
                                        </p>
                                        <div className="d-flex">
                                            <button
                                                onClick={() =>
                                                    setShowSection(
                                                        "biller-list"
                                                    )
                                                }
                                                className="see-billers-btn">
                                                See Billers
                                            </button>
                                        </div>
                                    </div>
                                    <div className="pay-bills-center">
                                        <div>
                                            <div>
                                                <img
                                                    className="pay-bills-main-img"
                                                    src="assets/img/pay-bills-main.png"
                                                    alt=""
                                                />
                                                <p className="add-your-p">
                                                    Add Your Biller Together
                                                </p>
                                                <p className="add-more-p">
                                                    Add More biller and make
                                                    Bill Payment Easy
                                                </p>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="add-btn-div">
                                                <button
                                                    onClick={() =>
                                                        setShowSection(
                                                            "add-form"
                                                        )
                                                    }
                                                    className="add-biller-btn">
                                                    Add Biller
                                                </button>
                                                {/* onClick={loginFormSubmit} */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {showSection == "biller-list" && (
                                <div>
                                    <div className="pay-bills-grp1">
                                        <p className="pay-bills-head">
                                            Biller List
                                        </p>
                                        <div className="d-flex">
                                            <button
                                                onClick={() =>
                                                    setShowSection("add-form")
                                                }
                                                className="see-billers-btn">
                                                Add Biller
                                            </button>
                                        </div>
                                    </div>

                                    <div className="local-search-biller-list">
                                        <input
                                            onKeyPress={(event: any) => {
                                                if (event.key === "Enter") {
                                                    localSearch(
                                                        event.target.value
                                                    );
                                                }
                                            }}
                                            onChange={sortArray}
                                            className="search-payers"
                                            placeholder="search with name or contact number"
                                        />
                                    </div>

                                    <div className="grid1-main">
                                        <div className="row">
                                            {resPayoutList?.length != 0 &&
                                                resPayoutList?.map(
                                                    (item: any) => {
                                                        return (
                                                            <div
                                                                key={
                                                                    item?.bankAccountNumber
                                                                }
                                                                className="col-sm-2 col-md-2 payee-col">
                                                                <div
                                                                    className="payee-col-sub"
                                                                    style={{
                                                                        cursor: "pointer",
                                                                    }}>
                                                                    <button
                                                                        onClick={() => {
                                                                            deletePayee(
                                                                                item?.payout_contactId
                                                                            );
                                                                        }}
                                                                        className="btn btn-danger delete-payout-btn">
                                                                        Delete
                                                                    </button>

                                                                    <h1
                                                                        onClick={() => {
                                                                            setCurrentPayee(
                                                                                item
                                                                            );
                                                                            setShowModal(
                                                                                true
                                                                            );
                                                                        }}
                                                                        className="first-letter">
                                                                        {item?.bankAccountName.charAt(
                                                                            0
                                                                        )}
                                                                    </h1>
                                                                    <div>
                                                                        <p
                                                                            onClick={() => {
                                                                                setCurrentPayee(
                                                                                    item
                                                                                );
                                                                                setShowModal(
                                                                                    true
                                                                                );
                                                                            }}
                                                                            className="ac-name">
                                                                            {
                                                                                item?.bankAccountName
                                                                            }
                                                                        </p>
                                                                        <p
                                                                            onClick={() => {
                                                                                setCurrentPayee(
                                                                                    item
                                                                                );
                                                                                setShowModal(
                                                                                    true
                                                                                );
                                                                            }}
                                                                            className="ac-number">
                                                                            a/c{" "}
                                                                            {
                                                                                item?.bankAccountNumber
                                                                            }
                                                                        </p>
                                                                        <p
                                                                            onClick={() => {
                                                                                setCurrentPayee(
                                                                                    item
                                                                                );
                                                                                setShowModal(
                                                                                    true
                                                                                );
                                                                            }}
                                                                            className="ac-number">
                                                                            {" "}
                                                                            {
                                                                                item?.mobileNumber
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    }
                                                )}
                                            {resPayoutList?.length == 0 && (
                                                <h3>No Results Found</h3>
                                            )}
                                            {/* {payoutList?.length == 0 &&
                                    <h2>No Contacts Found</h2>
                                    } */}
                                            {payoutList?.length == 0 && (
                                                <h2>No Billers, Please add</h2>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}

                            {showSection == "add-form" && (
                                <div>
                                    <div className="pay-bills-grp1">
                                        <p className="pay-bills-head">
                                            Pay Biller
                                        </p>
                                        {/* <button onClick={onBackClick} className="see-billers-btn" style={{width:'100px'}}>back</button> */}
                                        <div className="d-flex">
                                            <button
                                                onClick={() =>
                                                    setShowSection(
                                                        "biller-list"
                                                    )
                                                }
                                                className="see-billers-btn">
                                                See Billers
                                            </button>
                                        </div>
                                    </div>

                                    {showNameNumberForm && (
                                        <div className="form-main">
                                            <form
                                                className="form-sub"
                                                onSubmit={handleSubmit(
                                                    onNameNumberSubmit
                                                )}>
                                                {/* onChange={(e:any)=>setAddBlc(e.target.value)} */}

                                                <input
                                                    value={state.accountNumber}
                                                    disabled={true}
                                                    name="accountNumber"
                                                    id="accountNumber3"
                                                    className="pay-bill-ip"
                                                    type="text"
                                                    placeholder="Enter Bank Account Number"
                                                />

                                                <input
                                                    value={state.IFSCCode}
                                                    disabled={true}
                                                    name="IFSCCode"
                                                    id="IFSCCode3"
                                                    className="pay-bill-ip"
                                                    type="text"
                                                    placeholder="Enter IFSC Code"
                                                />

                                                <input
                                                    value={state.beneName}
                                                    disabled={true}
                                                    name="mobileNumber"
                                                    id="mobileNumber3"
                                                    className="pay-bill-ip"
                                                    type="text"
                                                    placeholder="Beneficiary Name"
                                                />

                                                <input
                                                    maxLength={10}
                                                    {...register(
                                                        "mobileNumber",
                                                        {
                                                            required: true,
                                                            pattern:
                                                                /^([0-9]{10})+$/i,
                                                        }
                                                    )}
                                                    name="mobileNumber"
                                                    id="mobileNumber3"
                                                    className="pay-bill-ip"
                                                    type="text"
                                                    placeholder="Enter Contact Number"
                                                />
                                                <label className="error-text">
                                                    {errors?.mobileNumber
                                                        ?.type == "required" &&
                                                        "Contact number is required "}{" "}
                                                    {errors?.mobileNumber
                                                        ?.type == "pattern" &&
                                                        "Mobile Number Invalid"}{" "}
                                                </label>

                                                <BounceLoader
                                                    color={"#6865F0"}
                                                    loading={loading}
                                                    css={override}
                                                    size={150}
                                                />
                                                <div
                                                    className="d-flex"
                                                    style={{
                                                        marginTop: "20px",
                                                    }}>
                                                    <button
                                                        type="submit"
                                                        className="add-biller-btn">
                                                        Add
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    )}

                                    {showIfscForm && (
                                        <div className="form-main">
                                            <form
                                                className="form-sub"
                                                onSubmit={handleSubmit(
                                                    onVerifySubmit
                                                )}>
                                                <input
                                                    {...register(
                                                        "accountNumber",
                                                        {
                                                            required: true,
                                                            pattern:
                                                                /^[0-9]+$/i,
                                                        }
                                                    )}
                                                    name="accountNumber"
                                                    id="accountNumber2"
                                                    className="pay-bill-ip"
                                                    type="text"
                                                    placeholder="Enter Bank Account Number"
                                                />
                                                <label className="error-text">
                                                    {errors?.accountNumber
                                                        ?.type == "required" &&
                                                        "Account number is required "}{" "}
                                                    {errors?.accountNumber
                                                        ?.type == "pattern" &&
                                                        "Account Number Invalid"}{" "}
                                                </label>

                                                <input
                                                    {...register("IFSCCode", {
                                                        required: true,
                                                        pattern:
                                                            /^[A-Za-z]{4}0[A-Z0-9a-z]{6}$/i,
                                                    })}
                                                    name="IFSCCode"
                                                    id="IFSCCode2"
                                                    className="pay-bill-ip"
                                                    type="text"
                                                    placeholder="Enter IFSC Code"
                                                />
                                                <label className="error-text">
                                                    {errors?.IFSCCode?.type ==
                                                        "required" &&
                                                        "IFSC code is required "}{" "}
                                                    {errors?.IFSCCode?.type ==
                                                        "pattern" &&
                                                        "IFSC code Invalid"}{" "}
                                                </label>

                                                <input
                                                    type="checkbox"
                                                    onClick={onCheckBoxClick}
                                                />
                                                <label>
                                                    check this if the Biller
                                                    account validation is
                                                    required
                                                </label>

                                                <BounceLoader
                                                    color={"#6865F0"}
                                                    loading={loading}
                                                    css={override}
                                                    size={150}
                                                />
                                                <p>
                                                    Rs4 will be deducted from
                                                    wallet for each Biller
                                                    account validation
                                                </p>
                                                <div
                                                    className="d-flex"
                                                    style={{
                                                        marginTop: "20px",
                                                    }}>
                                                    <button
                                                        type="submit"
                                                        className="add-biller-btn">
                                                        Verify
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    )}

                                    {showAllFormDetails && (
                                        <div className="form-main">
                                            <form
                                                className="form-sub"
                                                onSubmit={handleSubmit(
                                                    onSubmit
                                                )}>
                                                <input
                                                    maxLength={10}
                                                    {...register(
                                                        "mobileNumber",
                                                        {
                                                            required: true,
                                                            pattern:
                                                                /^([0-9]{10})+$/i,
                                                        }
                                                    )}
                                                    name="mobileNumber"
                                                    id="mobileNumber"
                                                    className="pay-bill-ip"
                                                    type="text"
                                                    placeholder="Enter Contact Number"
                                                />
                                                <label className="error-text">
                                                    {errors?.mobileNumber
                                                        ?.type == "required" &&
                                                        "Contact number is required "}{" "}
                                                    {errors?.mobileNumber
                                                        ?.type == "pattern" &&
                                                        "Mobile Number Invalid"}{" "}
                                                </label>

                                                <input
                                                    {...register("name", {
                                                        required: true,
                                                    })}
                                                    name="name"
                                                    id="name"
                                                    className="pay-bill-ip"
                                                    type="text"
                                                    placeholder="Enter Name"
                                                />
                                                <label className="error-text">
                                                    {" "}
                                                    {errors?.name &&
                                                        "Name is required"}{" "}
                                                </label>

                                                <input
                                                    {...register(
                                                        "accountNumber",
                                                        {
                                                            required: true,
                                                            pattern:
                                                                /^[0-9]+$/i,
                                                        }
                                                    )}
                                                    name="accountNumber"
                                                    id="accountNumber"
                                                    className="pay-bill-ip"
                                                    type="text"
                                                    placeholder="Enter Bank Account Number"
                                                />
                                                <label className="error-text">
                                                    {errors?.accountNumber
                                                        ?.type == "required" &&
                                                        "Account number is required "}{" "}
                                                    {errors?.accountNumber
                                                        ?.type == "pattern" &&
                                                        "Account Number Invalid"}{" "}
                                                </label>

                                                <input
                                                    {...register("IFSCCode", {
                                                        required: true,
                                                        pattern:
                                                            /^[A-Za-z]{4}0[A-Z0-9a-z]{6}$/i,
                                                    })}
                                                    name="IFSCCode"
                                                    id="IFSCCode"
                                                    className="pay-bill-ip"
                                                    type="text"
                                                    placeholder="Enter IFSC Code"
                                                />
                                                <label className="error-text">
                                                    {errors?.IFSCCode?.type ==
                                                        "required" &&
                                                        "IFSC code is required "}{" "}
                                                    {errors?.IFSCCode?.type ==
                                                        "pattern" &&
                                                        "IFSC code Invalid"}{" "}
                                                </label>

                                                <input
                                                    type="checkbox"
                                                    checked={true}
                                                    onClick={onCheckBoxClick}
                                                />
                                                <label>
                                                    uncheck this if the Biller
                                                    account validation is not
                                                    required
                                                </label>

                                                <BounceLoader
                                                    color={"#6865F0"}
                                                    loading={loading}
                                                    css={override}
                                                    size={150}
                                                />
                                                <p>
                                                    Rs4 will be deducted from
                                                    wallet for each Biller
                                                    account validation
                                                </p>
                                                <div
                                                    className="d-flex"
                                                    style={{
                                                        marginTop: "20px",
                                                    }}>
                                                    <button
                                                        type="submit"
                                                        className="add-biller-btn">
                                                        Add
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    )}
                                </div>
                            )}

                            <Modal
                                show={showModal}
                                size="lg"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                                dialogClassName="c-modal-pay-bills"
                                onHide={hideModel}>
                                <Modal.Body>
                                    <div className="m-name">
                                        <p className="m-name-payee">
                                            {currentPayee?.bankAccountName}
                                        </p>
                                        <p className="m-mobile-payee">
                                            {currentPayee?.mobileNumber}
                                        </p>
                                        <p className="m-mobile-payee">
                                            a/c{" "}
                                            {currentPayee?.bankAccountNumber}
                                        </p>
                                        <p className="m-mobile-payee">
                                            {" "}
                                            {currentPayee?.bankIFSCCode}
                                        </p>
                                        <div className="pay-ip-div">
                                            <input
                                                // autoComplete="off"
                                                onChange={(e: any) => {
                                                    const inputNumber =
                                                        parseFloat(
                                                            e.target.value.replace(
                                                                /[^0-9.]/g,
                                                                ""
                                                            )
                                                        );
                                                    setPayAmount(
                                                        isNaN(inputNumber)
                                                            ? ""
                                                            : inputNumber.toLocaleString(
                                                                  "en-IN",
                                                                  {
                                                                      maximumFractionDigits: 2,
                                                                  }
                                                              )
                                                    );
                                                }}
                                                name="amount"
                                                className="pay-ip-div-e1"
                                                type="text"
                                                autoComplete="off"
                                                placeholder="Enter Amount"
                                                value={payAmount}
                                            />
                                            {/* <p>
                                        {convertRupeesIntoWords(
                                            parseInt(payAmount)
                                        )}
                                    </p> */}
                                            {
                                                <div className="pay-otp">
                                                    <OtpInput
                                                        inputStyle={{
                                                            width: "30px",
                                                            border: "2px solid grey",
                                                            borderRadius: "4px",
                                                        }}
                                                        value={pinValue}
                                                        onChange={onPinChange}
                                                        numInputs={4}
                                                        isInputSecure={true}
                                                        separator={
                                                            <span
                                                                style={{
                                                                    margin: "10px",
                                                                }}></span>
                                                        }
                                                    />
                                                </div>
                                            }

                                            <textarea
                                                maxLength={450}
                                                onChange={(e: any) =>
                                                    setPayNotes(e.target.value)
                                                }
                                                name="notes"
                                                className="pay-ip-div-e2"
                                                placeholder="Add a Note"
                                            />
                                            <select
                                                onChange={payouthandler}
                                                defaultValue=""
                                                style={{
                                                    width: "100%",
                                                    height: "2rem",
                                                    marginTop: "1rem",
                                                }}>
                                                <option value="">
                                                    Bill payment Purpose
                                                </option>
                                                <option value="Education Fee">
                                                    Education Fee
                                                </option>
                                                <option value="Vendor Payment">
                                                    Vendor Payment
                                                </option>
                                                <option
                                                    value={"Housing Society"}>
                                                    Housing Society
                                                </option>

                                                <option
                                                    value={"Rental Payment"}>
                                                    Rental Payment
                                                </option>
                                                <option
                                                    value={
                                                        "Credit Card Bill Payment"
                                                    }>
                                                    Credit Card Bill Payment
                                                </option>
                                            </select>
                                            {/* <div onClick={()=>sendPayment(currentPayee)}> */}

                                            {loading == false && (
                                                <img
                                                    onClick={() =>
                                                        sendPayment(
                                                            currentPayee
                                                        )
                                                    }
                                                    className="arrow-btn-payee"
                                                    src="assets/img/arrow-right.png"
                                                    alt=""
                                                />
                                            )}

                                            <BounceLoader
                                                color={"#6865F0"}
                                                loading={loading}
                                                css={override}
                                                size={150}
                                            />

                                            {/* </div> */}

                                            <button
                                                onClick={
                                                    getLastTransactionList
                                                }>
                                                Last Transactions
                                            </button>
                                        </div>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </>
                    ) : (
                        <UpiContacts />
                    )}
                    <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </div>
            </div>
        </>
    );
}

export default PayBills;
