import React, { useEffect, useState } from "react";
import "./dashboard.css";
import {
    BrowserRouter as Router,
    Route,
    Routes,
    Link,
    useLocation,
} from "react-router-dom";
import { useNavigate } from "react-router-dom";
import LoginServices from "../../services/LoginServices";
import BbpsLogo from "../../assets/B.svg";
let DashboardNav = (props: any) => {
    // console.log(props);
    const navigate = useNavigate();
    const location = useLocation();

    const [userDetails, setUserDetails] = useState<any>();
    const userId = localStorage.getItem("userid");
    const [activatedPath, setactivatedPath] = useState("");
    const [AppSettings, setAppSettings] = useState<any>();
    // console.log(location);
    useEffect(() => {
        if (userId == "undefined") {
            alert("Login to see your dashboard");
            navigate("/");
        }
        if (userId) {
            let config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            LoginServices.getAppSettings(parseInt(userId), config).then(
                (Response: any) => {
                    // console.log(Response.data.Settings);
                    setAppSettings(Response?.data?.Settings);
                    if (Response.data.Status == 1) {
                    }
                }
            );
            LoginServices.getUserDetailsByID(parseInt(userId), config)
                .then((response: any) => {
                    // console.log(response);
                    if (response.data.Status == 1) {
                        setUserDetails(response?.data?.UserDetails);
                        localStorage.setItem(
                            "usertye",
                            response?.data?.UserDetails?.userType
                        );
                        localStorage.setItem(
                            "isKycDone",
                            response?.data?.UserDetails?.isKYCverified
                        );
                    } else {
                    }
                    // console.log(response.data);
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        }
    }, [""]);
    // const location = useLocation();
    // console.log(location);

    const onClickButton = (activelink: string) => {
        setactivatedPath(activelink);
    };

    return (
        <>
            <div>
                <div className="logo d-flex align-items-center">
                    <a href="https://www.payville.in" target={"_blank"}>
                        <img src="assets/img/dashboard_logo.svg" alt="" />
                    </a>
                    {/* <span>Payville</span> */}
                </div>
                <div className="user-avatar-div">
                    <div>
                        <Link to="/dashboard/profile">
                            {userDetails?.profilePic == "NA" && (
                                <img src="assets/img/user-avatar.png" alt="" />
                            )}
                            {userDetails?.profilePic != "NA" && (
                                <img src={userDetails?.profilePic} alt="" />
                            )}
                            {/* <img src="assets/img/user-avatar.png" alt="" /> */}
                            <p className="user-name">
                                {userDetails?.firstName}
                            </p>
                            {/* <p className="user-city">{cityname?.length != 0 ? cityname : 'NA'}</p> */}
                        </Link>
                    </div>
                </div>
                <ul className="nav-list">
                    <li
                        className={
                            location.pathname == "/dashboard/"
                                ? "selected-menuitem"
                                : ""
                        }>
                        <Link to="/dashboard/">
                            <div
                                className="row"
                                onClick={() => onClickButton("")}>
                                <div className="col-2">
                                    <img
                                        className="menu-icon"
                                        src={
                                            location.pathname == "/dashboard/"
                                                ? "assets/dashboard-icons/filled/dashboard.png"
                                                : "assets/dashboard-icons/without-fill/dashboard.png"
                                        }
                                        alt=""
                                    />
                                </div>
                                <div className="col-10">
                                    <span>Dashboard</span>
                                </div>
                            </div>
                        </Link>
                    </li>
                    {userDetails?.isWalletTransferEnabled == 1 && (
                        <li
                            className={
                                activatedPath == "wallet-transfer"
                                    ? "selected-menuitem"
                                    : ""
                            }>
                            <Link to="/dashboard/wallet-transfer">
                                <div
                                    className="row"
                                    onClick={() =>
                                        onClickButton("wallet-transfer")
                                    }>
                                    <div className="col-2">
                                        <img
                                            className="menu-icon"
                                            src={
                                                activatedPath ==
                                                "wallet-transfer"
                                                    ? "assets/dashboard-icons/filled/wallet.png"
                                                    : "assets/dashboard-icons/without-fill/wallet.png"
                                            }
                                            alt=""
                                        />
                                    </div>
                                    <div className="col-10">
                                        <span>Wallet Transfers</span>
                                    </div>
                                </div>
                            </Link>
                        </li>
                    )}
                    <li
                        className={
                            location.pathname == "/dashboard/deposits"
                                ? "selected-menuitem"
                                : ""
                        }>
                        <Link to="/dashboard/deposits">
                            <div
                                className="row"
                                onClick={() => onClickButton("deposits")}>
                                <div className="col-2">
                                    <img
                                        className="menu-icon"
                                        src={
                                            location.pathname ==
                                            "/dashboard/deposits"
                                                ? "assets/dashboard-icons/filled/deposit.png"
                                                : "assets/dashboard-icons/without-fill/deposit.png"
                                        }
                                        alt=""
                                    />
                                </div>
                                <div className="col-10">
                                    <span>Deposit</span>
                                </div>
                            </div>
                        </Link>
                    </li>
                    {userDetails?.isCardPaymentEnabled == 1 && (
                        <li
                            className={
                                location.pathname.includes(
                                    "/dashboard/saved-cards"
                                )
                                    ? "selected-menuitem"
                                    : ""
                            }>
                            <Link to="/dashboard/saved-cards">
                                <div
                                    className="row"
                                    onClick={() =>
                                        onClickButton("saved-cards")
                                    }>
                                    <div className="col-2">
                                        <img
                                            className="menu-icon"
                                            src={
                                                location.pathname.includes(
                                                    "/dashboard/saved-cards"
                                                )
                                                    ? "assets/dashboard-icons/filled/card.png"
                                                    : "assets/dashboard-icons/without-fill/card_withoutfill.png"
                                            }
                                            alt=""
                                        />
                                    </div>
                                    <div className="col-10">
                                        <span>Card Payments</span>
                                    </div>
                                </div>
                            </Link>
                        </li>
                    )}
                    {AppSettings?.isBBPSvisible === 1 && (
                        <li
                            className={
                                location.pathname == "/dashboard/bbps"
                                    ? "selected-menuitem"
                                    : ""
                            }>
                            <Link to="/dashboard/bbps">
                                <div
                                    className="row"
                                    onClick={() => onClickButton("bbps")}>
                                    <div className="col-2">
                                        <img
                                            className="menu-icon"
                                            src={
                                                activatedPath ==
                                                "/dashboard/bbps"
                                                    ? BbpsLogo
                                                    : BbpsLogo
                                            }
                                            alt=""
                                        />
                                    </div>
                                    <div className="col-10">
                                        <span>BBPS</span>
                                    </div>
                                </div>
                            </Link>
                        </li>
                    )}
                    <li
                        className={
                            location.pathname == "/dashboard/pay-bills"
                                ? "selected-menuitem"
                                : ""
                        }>
                        <Link to="/dashboard/pay-bills">
                            <div
                                className="row"
                                onClick={() => onClickButton("pay-bills")}>
                                <div className="col-2">
                                    <img
                                        className="menu-icon"
                                        src={
                                            location.pathname ==
                                            "/dashboard/pay-bills"
                                                ? "assets/dashboard-icons/filled/pay-bills.png"
                                                : "assets/dashboard-icons/without-fill/pay-bills.png"
                                        }
                                        alt=""
                                    />
                                </div>
                                <div className="col-10">
                                    <span>Pay Bills</span>
                                </div>
                            </div>
                        </Link>
                    </li>
                    <li
                        className={
                            location.pathname == "/dashboard/transactions"
                                ? "selected-menuitem"
                                : ""
                        }>
                        <Link to="/dashboard/transactions">
                            <div
                                className="row"
                                onClick={() => onClickButton("transactions")}>
                                <div className="col-2">
                                    <img
                                        className="menu-icon"
                                        src={
                                            location.pathname ==
                                            "/dashboard/transactions"
                                                ? "assets/dashboard-icons/filled/transactions.png"
                                                : "assets/dashboard-icons/without-fill/transactions.png"
                                        }
                                        alt=""
                                    />
                                </div>
                                <div className="col-10">
                                    <span>Transactions </span>
                                </div>
                            </div>
                        </Link>
                    </li>
                    {userDetails?.userType == "BUSINESS" && (
                        <li
                            className={
                                location.pathname == "/dashboard/referral-users"
                                    ? "selected-menuitem"
                                    : ""
                            }>
                            <Link to="/dashboard/referral-users">
                                <div
                                    className="row"
                                    onClick={() =>
                                        onClickButton("referral-users")
                                    }>
                                    <div className="col-2">
                                        <img
                                            className="menu-icon"
                                            src={
                                                location.pathname ==
                                                "dashboard/referral-users"
                                                    ? "assets/dashboard-icons/filled/transactions.png"
                                                    : "assets/dashboard-icons/without-fill/transactions.png"
                                            }
                                            alt=""
                                        />
                                    </div>
                                    <div className="col-10">
                                        <span>Referral Users </span>
                                    </div>
                                </div>
                            </Link>
                        </li>
                    )}
                    <li
                        className={
                            location.pathname == "/dashboard/statements"
                                ? "selected-menuitem"
                                : ""
                        }>
                        <Link to="/dashboard/statements">
                            <div
                                className="row"
                                onClick={() => onClickButton("statements")}>
                                <div className="col-2">
                                    <img
                                        className="menu-icon"
                                        src={
                                            location.pathname ==
                                            "/dashboard/statements"
                                                ? "assets/dashboard-icons/filled/statements.png"
                                                : "assets/dashboard-icons/without-fill/statements.png"
                                        }
                                        alt=""
                                    />
                                </div>
                                <div className="col-10">
                                    <span>Statements</span>
                                </div>
                            </div>
                        </Link>
                    </li>
                    {userDetails?.isKYCverified == 0 && (
                        <li
                            className={
                                location.pathname == "/dashboard/kyc"
                                    ? "selected-menuitem"
                                    : ""
                            }>
                            <Link to="/dashboard/kyc">
                                <div
                                    className="row"
                                    onClick={() => onClickButton("kyc")}>
                                    <div className="col-2">
                                        <img
                                            className="menu-icon"
                                            src={
                                                location.pathname ==
                                                "/dashboard/kyc"
                                                    ? "assets/dashboard-icons/filled/statements.png"
                                                    : "assets/dashboard-icons/without-fill/statements.png"
                                            }
                                            alt=""
                                        />
                                    </div>
                                    <div className="col-10">
                                        <span>KYC</span>
                                    </div>
                                </div>
                            </Link>
                        </li>
                    )}
                </ul>
            </div>
        </>
    );
};

export default DashboardNav;
