import React, { useState, useEffect, useRef } from "react";
import WalletBlcHeader from "./walletBlcHeader";
import LoginServices from "../../services/LoginServices";
import CardClasses from "./Saved.module.css";
import { useNavigate } from "react-router-dom";
import { Modal, ModalBody } from "react-bootstrap";
import OtpInput from "react-otp-input";
import { toast } from "react-toastify";
import Loader from "../Loader/Loader";
import { BounceLoader } from "react-spinners";
import { css } from "@emotion/react";

import { useForm, Controller, SubmitHandler, set } from "react-hook-form";
type FormData = {
    latitude: number;
    longitude: number;
    PIN: string;
    amount: number;
    ipAddress: number;
    cardId: number;
};
function SavedCard() {
    const override = css`
    display: block;
    margin: 0 auto;
    border-color: "#6865F0";
`;

    const navigate = useNavigate();
    const [walletBlc, setWalletBlc] = useState<any>(0);
    const [Edit, setEdit] = useState(false);
    const [loading, setLoading] = useState(true);
    const [success, setSuccess] = useState(false);
    const [successData, setSuccessData] = useState<any>();
    const [selectedata, SetSelectedData] = useState<any>({});
    const userId = localStorage.getItem("userid");
    const [refreshP, setRefreshP] = useState<any>("");
    const [Cards, setCards] = useState([]);
    const [AllCards, setAllCards] = useState([]);
    const [userIpAddress, setUserIpAddress] = useState("");

    // const [] = useState([]);
    const componentMounted = useRef(true);
    const {
        register,
        handleSubmit,
        watch,
        control,
        setValue,
        resetField,
        reset,
        setError,
        formState: { errors },
    } = useForm<FormData>();

    useEffect(() => {
        fetch("https://api.ipify.org/?format=json")
            .then((Response) => {
                return Response.json();
            })
            .then((response) => {
                console.log(response);
                setUserIpAddress(response.ip);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const getCards = () => {
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        if (userId) {
            LoginServices.GetCards(config, parseInt(userId))
                .then((response: any) => {
                    setLoading(false);
                    // console.log(response);

                    if (response.data.Status == 1 && componentMounted.current) {
                        // setWalletBlc(response?.data?.walletBalance);
                        setCards(response?.data?.Cards);
                        setAllCards(response?.data?.Cards);
                    } else {
                    }

                    // console.log(response.data);
                })
                .catch((e: Error) => {
                    setLoading(false);
                    console.log(e);
                });
        }
    };
    useEffect(() => {
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        if (userId) {
            LoginServices.getWalletBalanceByUserID(parseInt(userId), config)
                .then((response: any) => {
                    // console.log(response);

                    if (response.data.Status == 1 && componentMounted.current) {
                        setWalletBlc(response?.data?.walletBalance);
                    } else {
                    }

                    // console.log(response.data);
                })
                .catch((e: Error) => {
                    console.log(e);
                });
            getCards();
        }
        return () => {
            // This code runs when component is unmounted
            componentMounted.current = false; // (4) set it to false when we leave the page
        };
    }, [refreshP]);
    const onSubmit: SubmitHandler<FormData> = (data) => {
        let body = {
            ...data,
            ipAddress: userIpAddress,
            longitude: 0,
            latitude: 0,
            cardId: selectedata?.cardId,
        };
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        setLoading(true);
        LoginServices.MakeCardPayment(config, body)
            .then((response: any) => {
                // console.log(response);
                setLoading(false);

                console.log(response?.data);
                if (response.data.Status == 1) {
                    reset();
                    setSuccessData(response?.data);
                    setSuccess(true);
                    setEdit(false);
                    /* toast.success("Payment Success", {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    }); */
                } else {
                    toast.warning(response?.data?.Message, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setLoading(false);
                }

                // console.log(response.data);
            })
            .catch((e: Error) => {
                setLoading(false);
                console.log(e);
            });
    };
    const DeleteHandler = (e: any) => {
        e.stopPropagation();
        let CardId = e.currentTarget.getAttribute("custom-cardId");
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        if (window.confirm("Are you sure you want to delete this card?")) {
            setLoading(true);
            LoginServices.DeleteCard(config, CardId)
                .then((response: any) => {
                    setLoading(false);
                    console.log(response);
                    if (response.data.Status == 1) {
                        toast.success("Card Deleted", {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        getCards();
                    } else {
                        toast.warning(response?.data?.Message, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        setLoading(false);
                    }

                    // console.log(response.data);
                })
                .catch((e: Error) => {
                    setLoading(false);
                    console.log(e);
                });
        }
    };
    return (
        <div className="row main-row">
            {loading && <Loader />}
            <Modal
                show={success}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName="c-modal-pay-bills"
                onHide={() => {
                    setSuccess(false);
                    // setSuccessData({});
                    SetSelectedData({});
                    setEdit(false);
                }}>
                <Modal.Body>
                    {" "}
                    <div className={CardClasses["Modal_wrapper"]}>
                        <img
                            src={"assets/success.png"}
                            alt="Success"
                            style={{ width: "5rem", justifySelf: "center" }}
                        />
                        <h3>Transaction Successful</h3>
                        <div className={CardClasses["Card_success_wrapper"]}>
                            <div className={CardClasses["Card-details"]}>
                                <div>
                                    <h4> Card Number</h4>

                                    <p>
                                        {
                                            successData?.TransactionDetails
                                                ?.payee?.account
                                        }
                                    </p>
                                </div>
                                <div>
                                    <h4>Name</h4>
                                    <p>{selectedata?.cardHolderName}</p>
                                </div>

                                <div>
                                    <h4>Bank Name</h4>
                                    <p>
                                        {
                                            successData?.TransactionDetails
                                                ?.payee?.bankName
                                        }
                                    </p>
                                </div>
                                <div>
                                    <h4>Card Network</h4>
                                    <p>
                                        {
                                            successData?.TransactionDetails
                                                ?.payee?.cardNetwork
                                        }
                                    </p>
                                </div>
                                <div>
                                    <h4>External Ref</h4>
                                    <p>
                                        {
                                            successData?.TransactionDetails
                                                ?.externalRef
                                        }
                                    </p>
                                </div>
                                <div>
                                    <h4>Pool ReferenceId</h4>
                                    <p>
                                        {
                                            successData?.TransactionDetails
                                                ?.poolReferenceId
                                        }
                                    </p>
                                </div>
                                <div>
                                    <h4>Txn Value</h4>
                                    <p>
                                        {
                                            successData?.TransactionDetails
                                                ?.txnValue
                                        }
                                    </p>
                                </div>
                                <div>
                                    <h4>Txn charges</h4>
                                    <p>{successData?.Charges}</p>
                                </div>
                                <div>
                                    <h4>Txn ReferenceId</h4>
                                    <p>
                                        {
                                            successData?.TransactionDetails
                                                ?.txnReferenceId
                                        }
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>{" "}
            </Modal>
            <Modal
                show={Edit}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName="c-modal-pay-bills"
                onHide={() => {
                    if (!loading) {
                        setEdit(false);
                    }
                }}>
                <ModalBody>
                    <div className="m-name">
                        <p className="m-name-payee">
                            {selectedata?.cardHolderName}
                        </p>
                        <p className="m-mobile-payee">
                            Caard Number:xxxx xxxx xxxx{" "}
                            {selectedata?.cardNumber}
                        </p>
                        <form
                            onSubmit={handleSubmit(onSubmit)}
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                            }}>
                            <div className="pay-ip-div">
                                <input
                                    className="pay-ip-div-e1"
                                    type="number"
                                    {...register("amount", { required: true })}
                                    placeholder="Amount"
                                />

                                <p className={CardClasses["error"]}>
                                    {errors.amount?.message}
                                </p>
                            </div>
                            <div className="pay-otp">
                                {/* <label>PIN</label> */}
                                <Controller
                                    name="PIN"
                                    control={control}
                                    rules={{ required: "PIN is required" }}
                                    render={({ field }) => (
                                        <OtpInput
                                            {...field}
                                            inputStyle={{
                                                width: "30px",
                                                border: "2px solid grey",
                                                borderRadius: "4px",
                                            }}
                                            isInputSecure
                                            isInputNum
                                            value={field.value || ""}
                                            onChange={(value: string) =>
                                                setValue("PIN", value)
                                            } // Update react-hook-form value
                                            numInputs={4} // Set number of OTP fields
                                            separator={
                                                <span
                                                    style={{
                                                        margin: "10px",
                                                    }}></span>
                                            }
                                        />
                                    )}
                                />
                                {errors.PIN && <p>{errors.PIN.message}</p>}
                            </div>
                            <button
                                disabled={loading}
                                style={{
                                    border: "none",
                                    backgroundColor: "inherit",
                                }}>
                                {!loading ? (
                                    <img
                                        className="arrow-btn-payee"
                                        src="assets/img/arrow-right.png"
                                        alt=""
                                    />
                                ) : (
                                    <BounceLoader
                                        color={"#6865F0"}
                                        loading={loading}
                                        css={override}
                                        size={100}
                                    />
                                )}
                            </button>
                        </form>
                    </div>
                </ModalBody>
            </Modal>
            <div className="col-10 right-back">
                <WalletBlcHeader wBlc={walletBlc}></WalletBlcHeader>
                <header className={CardClasses["row_header"]}>
                    <h3>Credit Card Payments</h3>
                    <button
                        onClick={() => navigate("/dashboard/saved-cards/add")}>
                        Add Card
                    </button>
                </header>
                <input
                    className={CardClasses["input_search"]}
                    placeholder="Search By Phone Number"
                    onChange={(e) => {
                        setCards(
                            AllCards.filter((item: any) =>
                                item.mobileNumber.includes(e.target.value)
                            )
                        );
                    }}
                />
                <div className={CardClasses["Card_wrapper"]}>
                    {Cards.length > 0 ? (
                        Cards?.map((item: any) => (
                            <div
                                className={CardClasses["container"]}
                                onClick={() => {
                                    SetSelectedData(item);
                                    setEdit(true);
                                }}>
                                <div
                                    className={CardClasses["card"]}
                                    style={{ height: "200px" }}>
                                    <button
                                        className={CardClasses["Delete"]}
                                        onClick={DeleteHandler}
                                        custom-cardId={item.cardId}>
                                        Delete
                                    </button>
                                    <div className={CardClasses["card-inner"]}>
                                        <div className={CardClasses["front"]}>
                                            <img
                                                src="assets/map.png"
                                                className={
                                                    CardClasses["map-img"]
                                                }
                                            />
                                            <div className={CardClasses["row"]}>
                                                <img
                                                    src="assets/chip.png"
                                                    width="50px"
                                                />
                                                {item.network == "VISA" && (
                                                    <img
                                                        src="assets/visa.png"
                                                        width="50px"
                                                    />
                                                )}
                                                {item.network != "VISA" && (
                                                    <img
                                                        src="assets/mastercard.png"
                                                        width="60px"
                                                    />
                                                )}
                                            </div>
                                            {/*  <p style={{ marginBottom: 0 }}>
                                            {item.issuerBank}
                                        </p> */}
                                            <div>
                                                <div
                                                    className={`${CardClasses["row"]} ${CardClasses["card-no"]}`}>
                                                    <p>
                                                        {String(
                                                            item?.firstCardNumber
                                                        ).slice(0, 4)}
                                                    </p>
                                                    <p>
                                                        {" "}
                                                        {String(
                                                            item?.firstCardNumber
                                                        ).slice(4, 6)}
                                                        XX
                                                    </p>
                                                    <p>XXXX</p>
                                                    <p>{item?.cardNumber}</p>
                                                </div>
                                                <span>{item.issuerBank}</span>
                                            </div>
                                            <div
                                                className={`${CardClasses["row"]} ${CardClasses["card-holder"]}`}>
                                                <p>CARD HOLDER</p>
                                                <p>MOBILE NUMBER</p>
                                            </div>
                                            <div
                                                className={`${CardClasses["name"]} ${CardClasses["row"]}`}>
                                                <p>{item?.cardHolderName}</p>
                                                <p>{item?.mobileNumber}</p>
                                            </div>
                                        </div>
                                        {/*    <div className="back">
                              <img
                                  src="https://i.ibb.co/PYss3yv/map.png"
                                  className="map-img"
                              />
                              <div className="bar"></div>
                              <div className="row card-cvv">
                                  <div>
                                      <img src="https://i.ibb.co/S6JG8px/pattern.png" />
                                  </div>
                                  <p>824</p>
                              </div>
                              <div className="row card-text">
                                  <p>
                                      this is a virtual card design using
                                      HTML and CSS. You can aslo design
                                      something like this.
                                  </p>
                              </div>
                              <div className="row signature">
                                  <p>CUSTOMER SIGNATURE</p>
                                  <img
                                      src="https://i.ibb.co/WHZ3nRJ/visa.png"
                                      width="80px"
                                  />
                              </div>
                          </div> */}
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="pay-bills-center">
                            <div>
                                <div>
                                    <img
                                        className="pay-bills-main-img"
                                        src="assets/img/pay-bills-main.png"
                                        alt=""
                                    />
                                    <p className="add-more-p">
                                        No Card Found Add your Card
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default SavedCard;
