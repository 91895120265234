import { config } from "process";
import http from "../http-common";

const contactUsInfo = (data: any) => {
    return http.post("/ ", data);
};

const loginWithEmailPassword = (data: any) => {
    return http.post("/users/login/email", data);
};
const loginWithPhonePassword = (data: any) => {
    return http.post("/users/login/mobileNumber", data);
};
const loginVerfiyWithMobileNumber = (data: any) => {
    return http.post("/users/login/mobileNumber/verifyotp", data);
};
const getOtpForForgotPassword = (data: any) => {
    return http.post("/users/forgotpassword", data);
};
const verifyOtpForgot = (data: any) => {
    return http.post("/users/forgotpassword/verifyotp", data);
};
const updatePasswordForgot = (data: any) => {
    return http.post("/users/forgotpassword/updatepassword", data);
};
const getWalletBalanceByUserID = (userId: number, config: any) => {
    return http.get("users/" + userId + "/walletbalance", config);
};
const deleteWalletContact = (userId: number, contactId: any, config: any) => {
    return http.delete(
        "wallet/" + userId + "/wallet-contacts/" + contactId,
        config
    );
};
const createRazorpayOrder = (data: any, config: any) => {
    return http.post("/wallet/razorpay/orders", data, config);
};
const createPineLabsOrder = (data: any, config: any) => {
    return http.post("/wallet/pinelabs/web/orders", data, config);
};
const OrderIdStatus = (Id: any, config: any) => {
    return http.get(`/wallet/runpaisa/order/status?orderId=${Id}`, config);
};
const PayoutStatusCheck = (Id: any, config: any) => {
    return http.get(`/wallet/runpaisa/payout/status?orderId=${Id}`, config);
};
const createRazorpayPayment = (data: any, config: any) => {
    return http.post("/wallet/razorpay/payments", data, config);
};
const createWalletContact = (data: any, config: any) => {
    return http.post("/wallet/contacts", data, config);
};

const verifyAccountNumberAndIfsc = (userId: number, data: any, config: any) => {
    return http.post("wallet/" + userId + "/verify-bankdetails", data, config);
};

const getLastTenTransactions = (
    userId: number,
    payout_contactId: any,
    config: any
) => {
    return http.get(
        "wallet/" + userId + "/payout-transactions/" + payout_contactId,
        config
    );
};

const getDashboardDetails = (userId: number, config: any) => {
    return http.get("dashboard/" + userId + "/users", config);
};

const getPayoutContacts = (userId: number, config: any, type: any) => {
    return http.get(
        "wallet/" + userId + "/contacts?ContactType=" + type,
        config
    );
};
const payToContact = (data: any, config: any) => {
    return http.post("/wallet/payout", data, config);
};
const getWalletTransactions = (userId: number, config: any, type: any) => {
    return http.get(
        "wallet/" + userId + "/wallet-transactions?Type=" + type,
        config
    );
};
const exportWalletTransactions = (userId: number, data: any, config: any) => {
    return http.post(
        "wallet/" + userId + "/wallet-transactions/csv",
        data,
        config
    );
};
const paymentstatus = (id: any, config?: any) => {
    return http.get(`users/paymentgateway/status?paymentPageId=${id}`, config);
};
const paymentstatuswithoutlogin = (id: any, config?: any) => {
    return http.get(
        `users/paymentpage/paymentgateway/status?paymentPageId=${id}`,
        config
    );
};
const getWalletTransactionsByDate = (
    userId: number,
    dates: any,
    config: any,
    type: any
) => {
    return http.post(
        "wallet/" + userId + "/wallet-transactions?Offset=0&Type=" + type,
        dates,
        config
    );
};
const exportWalletTransactionByDate = (
    userId: any,
    dates: any,
    config: any,
    type: any
) => {
    return http.post(
        "wallet/" + userId + "/wallet-transactions/csv?Type=" + type,
        dates,
        config
    );
};
const walletTransactionbydatecustom = (
    userId: number,
    dates: any,
    config: any,
    offset: any
) => {
    return http.post(
        "wallet/" + userId + "/wallet-transactions?Offset=" + offset,
        dates,
        config
    );
};
const updatePassword = (userId: number, data: any, config: any) => {
    return http.put("/users/" + userId + "/updatepassword", data, config);
};
const updatePin = (userId: number, data: any, config: any) => {
    return http.post("/wallet/" + userId + "/set-securitypin", data, config);
};
const getUserDetailsByID = (userId: number, config: any) => {
    return http.get("users/" + userId, config);
};
const updateUserDetails = (userId: number, data: any, config: any) => {
    return http.put("/users/" + userId + "/update-userdetails", data, config);
};

const createWalletPayContact = (data: any, config: any) => {
    return http.post("/wallet/wallet-contacts", data, config);
};
const getWalletPayoutContacts = (userId: number, config: any) => {
    return http.get("wallet/" + userId + "/wallet-contacts", config);
};
const searchUserByNameOrMobile = (data: any, config: any) => {
    return http.post("/users/search-users", data, config);
};
const payToWallet = (data: any, config: any) => {
    return http.post("/wallet/transfer", data, config);
};
const updateReferralUserCommission = (userId: any, data: any, config: any) => {
    return http.put(
        "/users/" + userId + "/update-referraluser-commission",
        data,
        config
    );
};
const getTransactionsDetailsByID = (
    userId: number,
    transactionId: number,
    config: any
) => {
    return http.get(
        "/wallet/" + userId + "/wallet-transactions/" + transactionId,
        config
    );
};
const getCaptcha = (config: any) => {
    return http.get("/aadhar/getcaptcha", config);
};
const sendOtpKyc = (data: any, config: any) => {
    return http.post("/aadhar/getotp", data, config);
};
const sendVerifyOtp = (data: any, config: any) => {
    return http.post("/aadhar/verifyotp", data, config);
};
const sendPaymentPageID = (data: any) => {
    return http.post("/wallet/paymentpageid/orders", data);
};
const createRazorpayPaymentGlobal = (data: any) => {
    return http.post("/wallet/paymentpageid/payments", data);
};

const getRefListByUserId = (userId: any, config: any) => {
    return http.get("/users/" + userId + "/referred-users?offset=0", config);
};
const deletePayoutContact = (userId: number, payoutId: number, config: any) => {
    return http.delete("/wallet/" + userId + "/contacts/" + payoutId, config);
};
const getBusinessDetailsByPageId = (id: any) => {
    return http.get("/users/" + id + "/paymentpageid");
};
const getAppSettings = (userId: number, config: any) => {
    return http.get("/users/" + userId + "/settings", config);
};
const sendOTP = (data: any) => {
    return http.post("/aadhar/getotp", data);
};
const sendVerifyOTP = (data: any) => {
    return http.post("/aadhar/verifyotp3", data);
};

const submitNewPassword = (data: any) => {
    return http.post("/aadhar/verifyotp3", data);
};
const sendOtpForPinChange = (userId: number, data: any, config: any) => {
    return http.post(
        "/wallet/" + userId + "/set-securitypin/sendotp",
        data,
        config
    );
};
const FetchBillerListByCategoryName = (
    config: any,
    CategoryName: any,
    support: any
) => {
    return http.get(
        "/billpayment/billerlist?categoryName=" +
            CategoryName +
            "&QuickPaySupported=" +
            support,
        config
    );
};
const FetchBillerInfoPost = (data: any, config: any) => {
    return http.post("/billpayment/billerInfo", data, config);
};
const FetchBillDetails = (data: any, config: any) => {
    return http.post("/billpayment/billfetch", data, config);
};
const paybill = (data: any, config: any) => {
    return http.post("/billpayment/billpay", data, config);
};
const quickpaybill = (data: any, config: any) => {
    return http.post("/billpayment/quick-billpay", data, config);
};
const ComplaintRegister = (data: any, config: any) => {
    return http.post("/billpayment/register-complaint", data, config);
};
const TrackComplaint = (data: any, config: any) => {
    return http.post("/billpayment/track-complaint", data, config);
};
const transactionStatus = (data: any, config: any) => {
    return http.post("/billpayment/transaction-status", data, config);
};
const walletCommission = (userid: any, config: any) => {
    return http.get(
        "/users/" + userid + "/walletcommission-percentage",
        config
    );
};

const createPayoutUpi = (data: any, config: any) => {
    return http.post("wallet/contacts/upi", data, config);
};
const getUserDetails = (id: any, config: any) => {
    return http.get("users/" + id, config);
};
const VerifyCard = (data: any, config: any) => {
    return http.post("wallet/card/verify", data, config);
};
const AddCard = (data: any, config: any) => {
    return http.post("wallet/add-card", data, config);
};
const GetCards = (config: any, userId: any) => {
    return http.get(`wallet/${userId}/cards`, config);
};
const MakeCardPayment = (config: any, data: any) => {
    return http.post(`wallet/card/payment`, data, config);
};
const DeleteCard = (config: any, CardId: any) => {
    return http.delete(`wallet/${CardId}/cards`, config);
};

const LoginServices = {
    getUserDetails,
    DeleteCard,
    MakeCardPayment,
    VerifyCard,
    GetCards,
    AddCard,
    OrderIdStatus,
    transactionStatus,
    createPayoutUpi,
    getAppSettings,
    updateReferralUserCommission,
    PayoutStatusCheck,
    sendOtpForPinChange,
    contactUsInfo,
    getDashboardDetails,
    getLastTenTransactions,
    loginWithEmailPassword,
    loginWithPhonePassword,
    getWalletBalanceByUserID,
    createRazorpayOrder,
    createPineLabsOrder,
    createRazorpayPayment,
    createWalletContact,
    verifyAccountNumberAndIfsc,
    sendVerifyOTP,
    sendOTP,
    submitNewPassword,
    getPayoutContacts,
    payToContact,
    exportWalletTransactions,
    getWalletTransactions,
    getWalletTransactionsByDate,
    paymentstatuswithoutlogin,
    updatePassword,
    getUserDetailsByID,
    updateUserDetails,
    createWalletPayContact,
    getWalletPayoutContacts,
    searchUserByNameOrMobile,
    payToWallet,
    updatePin,
    getTransactionsDetailsByID,
    getCaptcha,
    sendOtpKyc,
    sendVerifyOtp,
    sendPaymentPageID,
    createRazorpayPaymentGlobal,
    getRefListByUserId,
    deletePayoutContact,
    getBusinessDetailsByPageId,
    getOtpForForgotPassword,
    verifyOtpForgot,
    updatePasswordForgot,
    loginVerfiyWithMobileNumber,
    deleteWalletContact,
    walletTransactionbydatecustom,
    exportWalletTransactionByDate,
    FetchBillerListByCategoryName,
    FetchBillerInfoPost,
    FetchBillDetails,
    paybill,
    quickpaybill,
    ComplaintRegister,
    TrackComplaint,
    walletCommission,
    paymentstatus,
};

export default LoginServices;
